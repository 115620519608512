export const ONE_SECOND_IN_MS = 1000;

export const ONE_MINUTE_IN_MS = 60 * ONE_SECOND_IN_MS;

export const ONE_HOUR_IN_MS = 60 * ONE_MINUTE_IN_MS;

export const ONE_DAY_IN_MS = 24 * ONE_HOUR_IN_MS;

export const ONE_WEEK_IN_MS = 7 * ONE_DAY_IN_MS;

export const ONE_MONTH_IN_MS = 30 * ONE_DAY_IN_MS;

export const ONE_YEAR_IN_MS = 365 * ONE_DAY_IN_MS;
